.vw-product-tabs .ui-widget-header, .vw-product-tabs.ui-widget-content {
    border:0;
    background:none;
    border-radius: 0;
}

.vw-product-tabs li.ui-state-default {
    border: 1px solid #ccc;
    border-bottom: 0;
}

.vw-product-tabs li.ui-state-hover {
    background: #efefef;
}

.vw-product-tabs li.ui-state-hover.ui-state-active {
    background: #fff;
}

.vw-product-tabs li.ui-state-default a {
    color:#444;
    font-weight:normal;
}

.vw-product-tabs li.ui-state-active a {
    font-weight:bold;
}

.vw-product-accordion .ui-widget-header, .vw-product-accordion .ui-widget-content {
    background:none;
    border: 1px solid #ccc;
    border-top:0;
    border-radius: 0;
}

.vw-product-accordion .ui-accordion-header {
    background:none;
    color:#444;
    font-weight: normal;
}

.vw-product-accordion .ui-accordion-header.ui-state-hover {
    border: 1px solid #aaa;
}

.vw-product-accordion .ui-accordion-header-active, .vw-product-accordion .ui-accordion-header-active.ui-state-hover {
    border: 1px solid #ccc;
    font-weight: bold;
    color:#444;
    background: #f5f5f5;
}

.vw-product-accordion .ui-accordion-header:focus {
    outline: none;
}

.vw-product-accordion .ui-accordion-header-active span {
    color:#444;
}

.vw-product-accordion li.ui-state-default {
    border: 1px solid #ccc;
    border-bottom: 0;
}

.vw-product-accordion li.ui-state-hover.ui-state-active {
    background: #ffffff;
}

.vw-product-accordion li.ui-state-default a {
    color:#444;
    font-weight:normal;
}

.vw-product-accordion li.ui-state-active a {
    font-weight:bold;
}

.vw-subheader {
    margin: 20px 0;
    font-weight:bold;
}

.vw-product-tabs .ui-tabs-panel, .vw-product-accordion .ui-accordion-content{
    padding-left: 0 !important;
}

.vw-product-accordion .ui-accordion-header, .vw-product-tabs-links-list li {
    transition: all 0.3s, border 1ms;
}

.vw-product-accordion .ui-accordion-header.ui-accordion-header-active, .vw-product-accordion .ui-accordion-header.ui-accordion-header-active.ui-state-hover {
    border-bottom: 0;
}

.vw-product-tabs .ui-icon, .ui-accordion-header .ui-accordion-header-icon.ui-icon-contract, .ui-accordion-header .ui-accordion-header-icon.ui-icon-expand {
    background-size: 12px;
    background-position: 2px 2px;
}

.vw-product-accordion .ui-accordion-header {
    margin-bottom:0 !important;
}

:focus {
    outline: 0 !important;
}

.vw-product-accordion .ui-accordion-content b,
.vw-product-tabs .ui-tabs-panel b {
	font-weight:bold; 
}

.vw-product-tabs ul, .vw-product-accordion ul {
    list-style: disc;
    padding-left: 1.5em;
}