.Polaris-Layout__Annotation {
  margin-left:0;
}

.Polaris-Page {
  margin-bottom:40px;
}

.Polaris-Layout__AnnotationWrapper {
  margin-left:0;
}

.Polaris-Layout__Annotation, .Polaris-Layout {
  margin-top:0;
}

.Polaris-Layout__Section {
  margin-top:0;
  flex: 2 2 24rem;
}
.note-popover {
  display: none;
}
.btn-sm {
  font-size: 1.5rem;
}

body {
  font-size: 1.4rem;
}

a:hover {
  text-decoration: none;
  color: initial;
}

@media (min-width: 40em) {
  body {
      font-size: 1.4rem;
  }
}

/* react summernote styles */

.note-editor .dropdown-toggle::after {
  display:none !important;
}

.note-editor .modal-header {
  flex-direction: row-reverse;
  align-items: center;
}

.note-editor .modal-body {
  padding: 2rem;
}

.note-editor .modal-title {
  font-weight: bold;
  margin-left: 1rem;
  font-size: 2rem;
}

.note-editor .close {
  margin:0;
  font-size: 2.5rem;
}

.note-editor input, .note-editor .btn, .note-editor label, .note-editor .modal-footer {
  font-size: 1.5rem;
  height: auto;
}

.tooltip .tooltip-inner {
  font-size: 1.3rem;
  max-width: inherit;
}

.note-editor .note-btn {
  font-size: 1.7rem;
}

.panel-heading.note-toolbar .dropdown-menu {
  min-width: 150px;
}

.note-editor .note-dimension-display {
  font-size: 1.5rem;
}

.note-editor .dropdown-menu a {
  color: #424242;
}

.note-editor .dropdown-menu a:hover {
  color: #000000;
}

.note-editor .dropdown-menu li {
  padding: 0.5em;
}

.note-editor .note-image-input {
  border: 0;
}

.note-editor .CodeMirror-code span, .note-editor .dropdown-menu a p, .note-editor .dropdown-menu a h1, .note-editor .dropdown-menu a h2, .note-editor .dropdown-menu a h3, .note-editor .dropdown-menu a h4, .note-editor .dropdown-menu a h5, .note-editor .dropdown-menu a h6 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.note-editable h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.note-editable h2 {
  font-size: 2.3rem;
  font-weight: bold;
}

.note-editable h3 {
  font-size: 2.1rem;
  font-weight: bold;
}

.note-editable h4 {
  font-size: 1.9rem;
  font-weight: bold;
}

.note-editable h5 {
  font-size: 1.7rem;
  font-weight: bold;
}

.note-editable h6 {
  font-size: 1.5rem;
  font-weight: bold;
}

.Polaris-Modal-Dialog__Modal {
  max-width: 75rem !important;
}

.Polaris-Modal-Dialog__Modal img {
  display: block;
  margin: 30px auto;
}