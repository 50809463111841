table {
    width: 100%;
    table-layout: fixed;
}

thead {
    background:white;
}

td {
    padding:10px 5px;
}

tr {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.ui-sortable-helper {
    display: table;
}