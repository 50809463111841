  .ui-scroll-tabs-header:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .ui-scroll-tabs-view {
    z-index: 1;
    overflow: hidden;
    margin-bottom: 0px !important;
  }
  
  .ui-scroll-tabs-view .ui-widget-header {
    border: none;
    background: transparent;
  }
  
  .ui-scroll-tabs-header {
    position: relative;
    overflow: hidden;
  }
  
  .ui-scroll-tabs-header .stNavMain {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 100%;
    opacity: 0;
    transition: left .5s, right .5s, opacity .8s;
    transition-timing-function: swing;
  }
  
  .ui-scroll-tabs-header .stNavMain button { height: 100%; }
  
  .ui-scroll-tabs-header .stNavMainLeft { left: -250px; }
  
  .ui-scroll-tabs-header .stNavMainLeft.stNavVisible {
    left: 0;
    opacity: 1;
  }
  
  .ui-scroll-tabs-header .stNavMainRight { right: -250px; }
  
  .ui-scroll-tabs-header .stNavMainRight.stNavVisible {
    right: 0;
    opacity: 1;
  }
  
  .ui-scroll-tabs-header ul.ui-tabs-nav {
    position: relative;
    white-space: nowrap;
  }
  
  .ui-scroll-tabs-header ul.ui-tabs-nav li {
    display: inline-block;
    float: none;
    line-height: 1.5em;
  }

  .ui-scroll-tabs-header ul {
    line-height:0;
  }
  
  .ui-scroll-tabs-header ul.ui-tabs-nav li.stHasCloseBtn a { padding-right: 0.5em; }
  
  .ui-scroll-tabs-header ul.ui-tabs-nav li span.stCloseBtn {
    float: left;
    padding: 4px 2px;
    border: none;
    cursor: pointer;
  }

  .ui-scroll-tabs-header .stNavMain button {
    margin-top: 0.25em;
    border: 0;
    outline: none !important;
  }

  .ui-scroll-tabs-header .ui-icon {
    top: -3px;
    position: relative
  }