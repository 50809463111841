.tab-zero .Polaris-Card, .tab-faq .Polaris-Card{
    margin-top:20px;
}

.tab-zero .Polaris-Layout__Section--secondary, .tab-faq .Polaris-Layout__Section--secondary {
    flex: 1 1 12rem !important;
}

.tab-zero .Polaris-DisplayText, .tab-faq .Polaris-DisplayText {
    margin-top: 20px;
}

.Polaris-Layout {
    margin-top:20px;
}

code {
    max-height: 200px;
    overflow-y: auto;
}

.mt-20 {
    margin-top: 20px;
}

.main-spinner {
    margin-top:7px;
}

div.edit {
    margin-top: 5px;
}
.Polaris-EmptyState__Image {
    margin-left: -40%;
    width: 130%;
}

.img-instructions {
    width: 100%;
    padding:20px 15px;
}

.pb-4 {
    padding-bottom: 1.5em;
}

.pt-4 {
    padding-top: 1.5em;
}

thead th {
    padding: 10px 5px;
}


.Polaris-Layout {
    margin-top:20px;
}

hr {
    margin-top:2rem !important;
    margin-bottom:2rem !important;
}

.react-toggle-track {
    height: 20px;
    width: 46px;
}

.react-toggle-thumb {
    height: 18px;
    width: 18px;
}

.loader .Polaris-DisplayText {
    text-align: center;
  }

.react-codemirror2 {
    font-size: 1.5em;
}

.title-case {
    text-transform: capitalize;
}

.Polaris-Banner--withinPage {
    margin-top: 20px;
    margin-bottom: 20px;
}

.Polaris-Layout__Section--oneHalf {
    flex: 1 1 !important;
}

html, body {
    background-color: #f4f6f8 !important;
}

.text-center {
    text-align: center;
}

img.block {
    display:block;
    margin:auto;
    height:120px;
}

.vw-subheader {
    font-size: 12px;
}

.tab-style .Polaris-Layout__Section--secondary {
    position:sticky;
    top: 35px;
}

.full-width {
    max-width: 100%;
}

.Polaris-Layout__AnnotationDescription {
    margin-top: 0 !important;
}

.Polaris-Modal-Dialog__Container { 
    z-index:1050; 
}

.note-editor .modal {
    background: rgba(0,0,0,0.5);
}